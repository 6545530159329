import React from 'react';
import PropTypes from 'prop-types';
import Image from 'components/common/utils/Image';
import './styles.sass';

class PractitionerInfo extends React.Component {
  render() {
    const { data } = this.props;
    const {
      qualification,
      name,
      practitionerInfo,
      pictures,
    } = data;
    const {
      speciality,
      bio,
    } = practitionerInfo;

    return (
      <div className="practitioner-info justify-content-start">
        <div className="d-flex practitioner-top-info">
          <div className="practitioner-image-div">
            <Image
              filename={pictures.round}
              alt={`${qualification} ${name}`}
              className="rounded-circle practitioner-info-face ml-0"
            />
          </div>
          <div className="practitioner-info-head text-left">
            <div className="practitioner-info-name">{name}</div>
            <div className="practitioner-info-qualification">
              {speciality}
            </div>
          </div>
        </div>
        <div className="practitioner-info-lore d-flex flex-column align-items-start">
          <span className="bio-span">Bio</span>
          <ul className="text-left pl-0 u-list-practitioner">
            {bio}
          </ul>
        </div>
      </div>
    );
  }
}
PractitionerInfo.propTypes = {
  data: PropTypes.shape({
    qualification: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    pictures: PropTypes.shape({
      square: PropTypes.string.isRequired,
    }),
    practitionerInfo: PropTypes.shape({
      speciality: PropTypes.string.isRequired,
      educationAndTitles: PropTypes.arrayOf(PropTypes.string),
      citiesOfPractice: PropTypes.string,
    }),
  }),
};
export default PractitionerInfo;
