import React from 'react';
import { Link } from 'gatsby';
import cx from 'classnames';
import Button from 'react-bootstrap/Button';

export default (props) => {
  const {
    path, children, variant, size, className, margin,
  } = props;
  return (
    <Link to={path} className="no-underline-hover">
      <Button variant={variant} size={size} className={cx('style-most-cta', margin ? 'cta-no-margin' : '')} className={cx('', className)}>
        <span>{children}</span>
      </Button>
    </Link>
  );
};
