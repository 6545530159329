import React, { useState } from 'react';
import CharlesMeta from 'components/common/meta/CharlesMeta';

import Container from 'react-bootstrap/Container';
import { Layout } from 'components/common';
import TextSectionList from 'components/common/TextSectionList';
import Section from 'components/common/Section';

import StyledLink from 'components/common/utils/StyledLink';
import VideoHowItWorks from 'components/common/VideoHowItWorks';

import { sections } from '../Landing/data';

import './styles.sass';
import { practitioners, medicalTeam } from '../common/data/doctors';
import PractitionerInfo from '../Landing/PractitionerInfo';
import { chunk } from 'lodash';
import DoctorInfo from '../Landing/DoctorInfo';
import VideoPL from '../common/VideoPL';

const IndexPage = () => {
  const [open, setOpen] = useState(false);
  const slicesBy3 = chunk(practitioners, 3);
  const previousNode = <div className="left-arrow-about-us-therapist-carousel"><div className="arrow-carousel arrow-carousel-left" /></div>;
  const nextNode = <div className="left-arrow-about-us-therapist-carousel"><div className="arrow-carousel" /></div>;
  return (
    <Layout
      footerCtaPath="/consulter-autres-problemes/"
      showLinksInFooter={false}
      backgroundBeforeFooter="background-color-pale-yellow"
      showNewsletterBeforeFooter={false}
    >
      <CharlesMeta
        data={{
          title: "Qui sommes-nous ? Découvrez les sexologues diplômées de Mia.co",
          description:
          "Mia.co est composée d'une équipe de sexologues diplômées, formées à la téléconsultation et suivie par un comité scientifique d'expertes francaises reconnues.",
        }}
      />
      <div className="text-left landing-page">
        <Section className="text-white d-flex flex-column background-color-pale-yellow home-top-section patho-top-section m-lg-0">
          <div className="desktop-home-top-div d-flex flex-column flex-lg-row align-items-center desktop-hiw-top-div justify-content-center justify-content-lg-start">
            <div className="home-top-left home-top-left-hiw">
              <div>
                <h1 className="h1-hiw-top-left">
                  Nos expertes vous aident à vous sentir mieux avec des solutions depuis chez vous
                </h1>
                <div className="d-flex mb-4 mt-4 justify-content-center justify-content-lg-start">
                  <StyledLink
                    variant="orange"
                    path="/consulter-autres-problemes/"
                    className=""
                  >
                    CONSULTER
                  </StyledLink>
                </div>
              </div>
            </div>
            <div className="d-flex flex-column desktop-videoHIW justify-content-lg-center align-items-lg-center">
              <VideoHowItWorks className="" />
              <span className="video-hiw-text mx-0">
                Je suis Camille Bataillon, la sexologue qui a créé ce protocole de téléconsultation.
              </span>
            </div>
          </div>
        </Section>
        <Section className="scientific-committee">
          <Container>
            <h2 className="h2-title text-center">NOTRE COMITÉ SCIENTIFIQUE</h2>
            <div className="d-flex flex-column flex-md-row flex-md-wrap align-items-center align-items-md-start">
              {medicalTeam.map((doctor) => (
                <DoctorInfo data={doctor} />
              ))}
            </div>
          </Container>
        </Section>
        <Section className="d-flex flex-column background-color-pale-yellow">
          <Container className="about-us-practitioner-container">
            <h2 className="h2-title text-center">NOS SEXOLOGUES</h2>
            <div className="mb-4 mb-lg-5">
              <div className="x-scroll-div d-flex text-wrap pl-3">
                {practitioners.map((doctor) => (
                  <div key={doctor.name} className="d-inline-block p-4 doctor-card">
                    <PractitionerInfo data={doctor} />
                  </div>
                ))}
              </div>
            </div>
          </Container>
        </Section>
        <Section className="manifesto-section">
          <Container className="manifesto-container">
            <h2 className="h2-title text-center">NOTRE MANIFESTE</h2>
            <div className="manifesto-div">
              <h3>Bienvenue sur Mia.co,</h3>
              <p>
                Nous sommes ravies de pouvoir vous y accueillir enfin !
              </p>
              <p>
                Ce projet est né il y a un peu plus d'un an. Nous avons
                {' '}
                <span className="weighter-span">modelé Mia.co</span>
                {' '}
                tou.tes.s
                ensemble afin de faire émerger un endroit
                {' '}
                <span className="weighter-span">sécurisant, bienveillant</span>
                . Un nouvel
                endroit où il n’y a
                {' '}
                <span className="weighter-span">pas de tabous</span>
                , où vous pouvez parler
                {' '}
                <span className="weighter-span">librement</span>
                {' '}
                et où vous
                allez être
                {' '}
                <span className="weighter-span">écoutées.</span>
              </p>
            </div>
            <div className="manifesto-div">
              <h3>Notre vocation ?</h3>
              <p>
                <span className="weighter-span">Améliorer votre bien-être sexuel :</span>
                {' '}
                en effet, chez Mia.co, nous sommes convaincues qu’une vie sexuelle qui vous
                rend heureuse a une grande part à jouer dans votre épanouissement personnel.
              </p>
              <p>
                Sur Mia.co, vous trouverez :
                <ul className="vocation-ul">
                  <li>des ressources,</li>
                  <li>des informations,</li>
                  <li>des conseils,</li>
                  <li>et des réponses à vos questions sexo.</li>
                </ul>
              </p>
              <p>
                Ces articles ont été conçus en collaboration avec des spécialistes de la sexualité
                féminine.
              </p>
              <p>
                <i>
                  Un sujet vous intéresse, et vous n’avez pas trouvé l’article correspondant ?
                  Contactez-nous et nous l'écrirons pour vous. L’échange est un des maîtres mots
                  chez Mia.co.
                </i>
              </p>
              <p>
                <span className="weighter-span">
                  Si vous en ressentez le besoin, vous avez la possibilité d’aller plus loin.
                </span>
              </p>
              <p>
                En cliquant sur "consulter" vous serez mise en relation avec une sexologue
                diplômée spécialisée dans tout ce qui attrait à votre
                {' '}
                <span className="weighter-span">bien-être intime.</span>
              </p>
              <p>
                Nous avons construit avec attention, une équipe qui vous permettra :
                <ul>
                  <li>
                    de trouver des
                    {' '}
                    <span className="weighter-span">solutions durables</span>
                    {' '}
                    à vos troubles sexuels,
                  </li>
                  <li>de poser toutes les questions autour de la sexualité dans son ensemble,</li>
                  <li>d’exploiter l’étendue de votre sexualité.</li>
                </ul>
              </p>
              <p>
                Libido, orgasme, post-partum, douleurs, relations de couple, pleine conscience,
                plaisir, désir :
                {' '}
                <span className="weighter-span">nous sommes là pour vous.</span>
              </p>
            </div>
            <div className="manifesto-div">
              <h3>Comment ?</h3>
              <p>
                Via des séances en visioconférence, par téléphone ou messagerie. Sur Mia.co,
                votre anonymat et vos données sont respectées et sécurisées.
              </p>
              <p>
                Grâce à la communauté Mia.co, vous pourrez échanger, de manière libre et anonyme
                avec des centaines de femmes, de tout âge et de tous horizons autour de la
                sexualité. De vos questionnements, de vos expériences, de ce qui vous intrigue ou
                ce qui vous rassure.
              </p>
              <p className="d-none d-lg-inline">Soyez la bienvenue.</p>
            </div>
            <p className="we-are-mia-p">
              <span className="d-lg-none">Soyez la bienvenue.</span>
              <span>Nous sommes Mia.co :</span>
            </p>
            <p>
              <i>
                Camille Bataillon, Charlotte Tourmente, Daphné Desmettre, Elise Bouquet,
                Estelle Lemaire, Sandrine Deat, Valérie Braem, Vanessa Cortesi, Veronique Baranska
                et Camille, Charles, Dalila, Diane, Dimitri, Lucas, Marion, Maxence, Olivier, Simon,
                Tristan B, Tristan C, Ulysse.
              </i>
            </p>
          </Container>
        </Section>
        <Section className="d-flex flex-column align-items-center faq-section">
          <Container className="d-flex flex-column align-items-center">
            <h3 className="section-title text-center">
              FAQ
            </h3>
            <TextSectionList sections={sections} />
          </Container>
        </Section>
        <Section className="two-womens-section">
          <StyledLink
            variant="orange"
            path="/consulter-autres-problemes/"
            className=""
            margin
          >
            CONSULTER
          </StyledLink>
        </Section>
      </div>
    </Layout>
  );
};

export default IndexPage;
