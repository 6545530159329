import React from 'react';
import Image from 'components/common/utils/Image';
import './styles.sass';

class DoctorInfo extends React.Component {
  render() {
    const {
      data: {
        qualification, name, medicalTeamInfo, pictures,
      },
    } = this.props;
    const { lines } = medicalTeamInfo;
    return (
      <div className="landing-doctor">
        <div className="landing-doctor-face">
          <Image filename={pictures.round} alt={`${qualification} ${name}`} className="doctor-face" />
        </div>
        <div className="landing-doctor-name mt-1">
          {qualification}
        </div>
        <div className="landing-doctor-name">{name}</div>
        <div className="landing-doctor-lore small">
          {lines}
        </div>
      </div>
    );
  }
}

export default DoctorInfo;
