import React from 'react';
import AboutUs from 'components/AboutUs';

// import Image from 'components/image'@
// import SEO from 'components/seo'
/* {
   <SEO title="Home" keywords={[`gatsby`, `application`, `react`]} />
} */

const UsPage = () => <AboutUs />;

export default UsPage;
