import React from 'react';
import Section from 'components/common/Section';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import VideoThumbnail from 'components/common/VideoThumbnail';

const HowItWorksSection = (video) => {
  const shouldShowTexAside = video.summary || video.intro;
  return (
    <div className="video-section-main-div">
      { video.titleDisplay === true && (
        <h2 className="h1 section-title h2-video-section">{video.title}</h2>
      )}
      <div>
        {shouldShowTexAside && (
          <div
            className="align-self-start"
          >
            {video.intro && (
              <span>{video.intro}</span>
            )}
            {video.summary && (
              <ul className="d-none d-md-block mt-4 how-it-works-steps">
                {video.summary.map((element) => (
                  <li key={element.id}>
                    <span className="d-block d-md-inline" />
                    <span className="font-weight-bold">{element.time}</span>
                    <p className="small font-weight-lighter">
                      {element.text}
                    </p>
                  </li>
                ))}
              </ul>
            )}
          </div>
        )}
        <div
          className="text-center video-section-thumbnail-div"
          style={{ maxWidth: '635px' }}
        >
          <VideoThumbnail
            data={video}
            showPlayIcon
          />
        </div>
      </div>
    </div>
  );
};

export default HowItWorksSection;
