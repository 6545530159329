const doctors = [
  {
    qualification: 'Sexologue',
    name: 'Charlotte Tourmente',
    lastName: 'Tourmente',
    position: 1,
    practitionerInfo: {
      speciality: 'Sexologue',
      bio: 'Charlotte est sexologue diplômée et membre du Syndicat National des'
        + " Sexologues Cliniciens. En plus d'accompagner les femmes souffrant de difficultés "
        + 'sexuelles, elle est aussi journaliste pour différents médias nationaux, écrits'
        + " ou télévisés, et s'attèle à sensibiliser et informer le grand public sur la"
        + ' sexualité.',
    },
    pictures: {
      square: 'doctors/charlotte-tourmente.jpg',
      round: 'doctors/round/charlotte-tourmente.png',
    },
    section: [
      'libido',
      'douleur',
      'plaisir',
      'epanouissement',
    ],
  },
  {
    qualification: 'Sexologue',
    name: 'Camille Bataillon',
    lastName: 'Bataillon',
    position: 2,
    medicalTeamInfo: {
      lines: [
        'Sexologue clinicienne et thérapeute de couple.',
      ],
    },
    practitionerInfo: {
      speciality: 'Sexologue',
      bio: 'Camille est sexologue diplômée avec une formation complémentaire à'
        + ' la thérapie de couple et la thérapie de pleine conscience. Elle est également'
        + ' spécialisée en sexo-périnatalité. Elle participe régulièrement dans les médias'
        + " à l'information du grand public sur la sexualité et l'épanouissement sexuel."
    },
    pictures: {
      yellow: 'doctors/marie-helene-yellow-square.png',
      gray: 'doctors/camille-bataillon.jpg',
      regular: 'doctors/camille-bataillon.jpg',
      square: 'doctors/camille-bataillon.jpg',
      round: 'doctors/round/camille-bataillon.png',
    },
    section: [
      'libido',
      'douleur',
      'plaisir',
      'couple',
      'epanouissement',
    ],
  },
  {
    qualification: 'Sexologue',
    name: 'Vanessa Cortesi',
    lastName: 'Cortesi',
    position: 3,
    practitionerInfo: {
      speciality: 'Thérapeute de couple',
      bio: "Vanessa est spécialisée dans les thérapies de couple. Elle dispose d'une"
        + ' expérience déterminante dans la prise en charge des couples qui traversent des'
        + ' difficultés ou qui souhaitent mieux se connaître. Psychologue clinicienne, elle'
        + ' est également qualifiée pour mener à bien des thérapies familiales.',
    },
    pictures: {
      square: 'doctors/vanessa-cortesi.JPG',
      round: 'doctors/round/vanessa-cortesi.png',
    },
    section: [
      'couple',
    ],
  },
  {
    qualification: 'Docteur',
    name: 'Béatrice Cuzin',
    lastName: 'Cuzin',
    position: 4,
    medicalTeamInfo: {
      lines: [
        'Médecin sexologue, Présidente de la Société Francophone de Médecine Sexuelle',
      ],
    },
    pictures: {
      gray: 'doctors/beatrice-cuzin.png',
      regular: 'doctors/beatrice-cuzin.png',
      square: 'doctors/beatrice-cuzin.png',
      round: 'doctors/round/beatrice-cuzin.png',
    },
  },
  {
    qualification: 'Docteur',
    name: 'Gilbert Bou Jaoudé',
    lastName: 'Bou Jaoudé',
    position: 5,
    medicalTeamInfo: {
      lines: [
        'Médecin sexologue, Directeur du Centre d’Etudes et de Traitements des Dysfonctions Sexuelles et du Couple',
      ],
    },
    pictures: {
      yellow: 'doctors/gilbert-yellow-square.png',
      gray: 'doctors/gilbert.jpg',
      regular: 'doctors/gilbert.jpg',
      square: 'doctors/sq-gilbert-bou-jaoude-2.jpg',
      round: 'doctors/round/gilbert-boujaoude.png',
    },
  },
  {
    qualification: 'Sexologue',
    name: 'Daphné Desmettre',
    lastName: 'Desmettre',
    practitionerInfo: {
      speciality: 'Sexologue',
      bio: 'Daphné est sexologue diplômée avec une formation complémentaire en'
        + ' sexocorporel et en hypnose clinique. Psychologue clinicienne de formation, elle'
        + ' accompagne les femmes et les couples qui rencontrent des difficultés sexuelles et'
        + " participe également à l'enseignement universitaire en sexologie.",
    },
    pictures: {
      square: 'doctors/daphne-desmettre.jpg',
      round: 'doctors/round/daphne-desmettre.png',
    },
    section: [
      'libido',
      'douleur',
      'plaisir',
      'couple',
      'epanouissement',
    ],
  },
  {
    qualification: 'Sexologue',
    name: 'Sladjana Decock',
    lastName: 'Decock',
    practitionerInfo: {
      speciality: 'Sexologue',
      bio: 'Sladjana est sexologue diplômée avec une formation complémentaire dans le'
        + ' domaine des Infections Sexuellement Transmissibles. En tant qu\'infirmière sexologue,'
        + ' elle a une approche médico-psychologique dans la prise en charge des troubles sexuels '
        + 'féminins. Elle est également sexologue auprès de l\'Agence Régionale de'
        + ' Santé avec une compétence dans les comportements sexuels à risque.',
    },
    pictures: {
      square: 'doctors/ana-sladjana.jpeg',
      round: 'doctors/round/sladjana-decock.png',
    },
    section: [
      'libido',
      'douleur',
      'plaisir',
      'couple',
      'epanouissement',
    ],
  },
  {
    qualification: 'Sexologue',
    name: 'Elise Bouquet',
    lastName: 'Bouquet',
    practitionerInfo: {
      speciality: 'Sexologue',
      bio: 'Elise est sexologue diplômée avec une formation spécifique sur le périnée'
        + ' féminin. Elle assure un suivi auprès des femmes qui souffrent de difficultés sexuelles et'
        + ' les accompagne dans différentes étapes de leur vie intime',
    },
    pictures: {
      square: 'doctors/elise-bouquet.jpeg',
      round: 'doctors/round/elise-bouquet.png',
    },
    section: [
      'libido',
      'douleur',
      'plaisir',
      'epanouissement',
    ],
  },
  {
    qualification: 'Sexologue',
    name: 'Estelle Lemaire',
    lastName: 'Lemaire',
    practitionerInfo: {
      speciality: 'Sexologue',
      citiesOfPractice: 'Saint-Pabu',
      bio: 'Estelle est sexologue diplômée avec une formation complémentaire à la'
        + ' psychothérapie Intégrative. Sexologue et psychologue, elle est habituée à'
        + " l'accompagnement de femmes et de couples qui souffrent de difficultés sexuelles,"
        + ' et ce, grâce à une approche multidisciplinaire.',
    },
    pictures: {
      square: 'doctors/estelle-lemaire.jpg',
      round: 'doctors/round/estelle-lemaire.png',
    },
    section: [
      'libido',
      'douleur',
      'plaisir',
      'couple',
      'epanouissement',
    ],
  },
  {
    qualification: 'Sexologue',
    name: 'Sandrine Deat',
    lastName: 'Deat',
    practitionerInfo: {
      speciality: 'Sexologue',
      bio: "Sandrine est sexologue diplômée et membre de l'Association Interdisciplinaire"
        + " Post Universitaire de Sexologie. Elle dispose d'une expérience"
        + " particulière dans l'accompagnement des femmes. Elle apporte un suivi auprès de"
        + ' celles qui font face à des difficultés sexuelles et les accompagne sur le chemin'
        + " de l'épanouissement de leur vie intime.",
    },
    pictures: {
      square: 'doctors/sandrine-deat.jpg',
      round: 'doctors/round/sandrine-deat.png',
    },
    section: [
      'libido',
      'douleur',
      'plaisir',
      'epanouissement',
    ],
  },
  {
    qualification: 'Sexologue',
    name: 'Valérie Braem',
    lastName: 'Braem',
    practitionerInfo: {
      speciality: 'Sexologue',
      bio: "Valérie est sexologue diplômée et certifiée en thérapies brèves. Psychologue expérimentée, elle s'intéresse particulièrement à l'accompagnement des femmes dans leur recherche d'épanouissement sexuel via le recours aux méthodes comportementales et aux thérapies orientées solutions. Elle a également une expérience dans l'accompagnement des personnes en souffrance au travail ou victimes de violences sexistes ou sexuelles"
    },
    pictures: {
      square: 'doctors/valerie-braem.jpg',
      round: 'doctors/round/valerie-braem.png',
    },
    section: [
      'libido',
      'douleur',
      'plaisir',
      'couple',
      'epanouissement',
    ],
  },
  {
    qualification: 'Sexologue',
    name: 'Véronique Baranska',
    lastName: 'Baranska',
    practitionerInfo: {
      speciality: 'Sexologue',
      bio: "Véronique est sexologue diplômée, membre de l'Association Interdisciplinaire post Universitaire de Sexologie et du SNSC. Infirmière, licenciée en psychologie, elle s'occupe régulièrement de femmes souffrant de difficultés sexuelles. Elle est également auteure d'ouvrages grand public pour sensibiliser et faire tomber les tabous autour de l'éducation à la sexualité.",
    },
    pictures: {
      square: 'doctors/veronique-baranska.jpg',
      round: 'doctors/round/veronique-baranska.png',
    },
    section: [
      'libido',
      'douleur',
      'plaisir',
      'epanouissement',
    ],
  },
  {
    qualification: 'Sexologue',
    name: 'Diane Deswarte',
    lastName: 'Deswarte',
    practitionerInfo: {
      speciality: 'Sexologue',
      bio: 'Diane est sexologue diplômée et membre du Réseau de Santé Sexuelle Publique. Elle propose des thérapies brèves et se base sur une approche systémique et multidisciplinaire pour proposer des solutions adaptées à chacune et chacun. Ses sujets de prédilection ? Le désir et le plaisir.',
    },
    pictures: {
      square: 'doctors/dianedeswarte.jpeg',
      round: 'doctors/round/diane-deswarte.png',
    },
    section: [
      'libido',
      'douleur',
      'plaisir',
      'epanouissement',
    ],
  },
];
const medicalTeam = doctors
  .filter((doctor) => !!doctor.medicalTeamInfo)
  .sort((a, b) => a.position - b.position);
const practitioners = doctors
  .filter((doctor) => !!doctor.practitionerInfo)
  .sort((a, b) => a.position - b.position);
const libido = practitioners
  .filter((doctor) => doctor.section.includes('libido'))
  .sort((a, b) => a.lastName.localeCompare(b.lastName));
const coupleTeam = practitioners
  .filter((doctor) => doctor.section.includes('couple'))
  .sort((a, b) => a.lastName.localeCompare(b.lastName));

export {
  medicalTeam, practitioners, doctors, libido, coupleTeam,
};
